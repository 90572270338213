<template>
  <grid-container form-container>
    <el-form @submit.native.prevent label-position="left">
      <el-form-item v-if="loginError.show">
        <el-alert type="error" :title="loginError.message" @close="loginError.show = false" />
      </el-form-item>

      <el-form-item v-if="$v.user.email.required && $v.user.email.$error">
        <el-alert type="error" :title="$t('Account.errors.validEmail')" />
      </el-form-item>

      <el-form-item v-if="$v.user.password.$error">
        <el-alert type="error" :title="$t('Account.errors.validPassword')" />
      </el-form-item>

      <el-form-item :label="$t('Account.email.label')">
        <el-input
          autofocus
          type="text"
          :placeholder="$t('Account.email.placeholder')"
          :name="$t('Account.email.placeholder')"
          clearable
          v-model="user.email"
          @blur="$v.user.email.$touch()"
          @keyup.enter.native="loginOnEnter"
        />
      </el-form-item>

      <el-form-item>
        <template slot="label">
          <el-row>
            <el-col :span="12">{{ $t('Account.password.label') }}</el-col>
            <el-col :span="12">
              <div
                @click="changePasswordDisplay"
                class="el-button el-button--text label-button">
                {{ $t(`Account.password.${showPasswordState}`) }}
                <i class="fa" :class='showPasswordClass' aria-hidden="true"></i>
              </div>
            </el-col>
          </el-row>
        </template>

        <el-input
          :type="passwordType"
          :placeholder="$t('Account.password.Enter')"
          :name="$t('Account.password.Enter')"
          clearable
          v-model="user.password"
          @blur="$v.user.password.$touch()"
          @keyup.enter.native="loginOnEnter"
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          class="width-100"
          @click="login"
          :disabled="$v.user.$invalid"
          type="primary">{{ $t('actions.Login') }}</el-button>
      </el-form-item>
    </el-form>
  </grid-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  mixins: [ validationMixin ],
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      loginError: {
        show: false,
        message: ''
      },
      passwordType: 'password'
    }
  },
  computed: {
    route() {
      return this.$route
    },

    showPasswordState() {
      return (this.passwordType === 'password') ? 'Show' : 'Hide'
    },

    showPasswordClass() {
      return (this.passwordType === 'password') ? 'fa-eye' : 'fa-eye-slash'
    }
  },
  validations: {
    user: {
      email: {
        required,
        email: function(val) {
          if (!this.$isProduction) return true
          return email(val)
        }
      },
      password: {
        required,
        minLength: function(val) {
          if (!this.$isProduction) return true
          return minLength(8)(val)
        }
      }
    }
  },
  methods: {
    changePasswordDisplay() {
      this.passwordType = (this.passwordType === 'password') ? 'text' : 'password'
    },

    loginOnEnter() {
      if (!this.$v.user.$invalid) {
        this.login()
      }
    },

    login() {
      const data = {
        username: this.user.email,
        password: this.user.password
      }

      this.$store.dispatch('login', {
        data,
        route: this.route
      })
        .catch(err => {
          this.loginError.show = true
          this.loginError.message = err
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~vars";

  .label-button {
    font-size: $-font-size-s;
    padding: 8px;
    float: right;
  }
</style>
