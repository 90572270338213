var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "grid-container",
    { attrs: { "form-container": "" } },
    [
      _c(
        "el-form",
        {
          attrs: { "label-position": "left" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _vm.loginError.show
            ? _c(
                "el-form-item",
                [
                  _c("el-alert", {
                    attrs: { type: "error", title: _vm.loginError.message },
                    on: {
                      close: function($event) {
                        _vm.loginError.show = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.$v.user.email.required && _vm.$v.user.email.$error
            ? _c(
                "el-form-item",
                [
                  _c("el-alert", {
                    attrs: {
                      type: "error",
                      title: _vm.$t("Account.errors.validEmail")
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.$v.user.password.$error
            ? _c(
                "el-form-item",
                [
                  _c("el-alert", {
                    attrs: {
                      type: "error",
                      title: _vm.$t("Account.errors.validPassword")
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Account.email.label") } },
            [
              _c("el-input", {
                attrs: {
                  autofocus: "",
                  type: "text",
                  placeholder: _vm.$t("Account.email.placeholder"),
                  name: _vm.$t("Account.email.placeholder"),
                  clearable: ""
                },
                on: {
                  blur: function($event) {
                    return _vm.$v.user.email.$touch()
                  }
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.loginOnEnter($event)
                  }
                },
                model: {
                  value: _vm.user.email,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _vm._v(_vm._s(_vm.$t("Account.password.label")))
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "el-button el-button--text label-button",
                            on: { click: _vm.changePasswordDisplay }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "Account.password." + _vm.showPasswordState
                                  )
                                ) +
                                "\n              "
                            ),
                            _c("i", {
                              staticClass: "fa",
                              class: _vm.showPasswordClass,
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-input", {
                attrs: {
                  type: _vm.passwordType,
                  placeholder: _vm.$t("Account.password.Enter"),
                  name: _vm.$t("Account.password.Enter"),
                  clearable: ""
                },
                on: {
                  blur: function($event) {
                    return _vm.$v.user.password.$touch()
                  }
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.loginOnEnter($event)
                  }
                },
                model: {
                  value: _vm.user.password,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "password", $$v)
                  },
                  expression: "user.password"
                }
              })
            ],
            2
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "width-100",
                  attrs: { disabled: _vm.$v.user.$invalid, type: "primary" },
                  on: { click: _vm.login }
                },
                [_vm._v(_vm._s(_vm.$t("actions.Login")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }